$list-space: 50px;
$table-border: 1px solid #E7E7E7;

.scheduleContainer {
  padding: 60px 90px !important;
  .header {
    display: flex;
    justify-content: space-between;
    align-content: center;
    flex-wrap: nowrap;
    margin-bottom: 50px;
  }
  .logo {
    width: 175px;
  }
  .address {
    list-style: none;
    font-weight: 600;
    margin: 0;
    li {
      margin-bottom: 4px;
    }
    .companyNumber {
      font-weight: 400;
    }
  }
  .heading {
    width: 650px;
    margin: 0 auto 18px;
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    h2,
    h3 {
      line-height: 45px;
    }
    .policyNumber {
      font-size: 18px;
    }
  }
  .inlineTable {
    display: inline-table;
    span {
      display: block;
      & + span {
        margin-top: 5px;
      }
    }
  }
  ol {
    list-style-type: none;
    counter-reset: item;
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    li {
      display: table;
      position: relative;
      counter-increment: item;
      margin-bottom: 14px;
      padding-left: $list-space;
      &::before {
        content: counters(item, '.') '. ';
        display: table-cell;
        position: absolute;
        left: 0;
        font-size: 14px;
        font-weight: 700;
        line-height: 26px;
      }
      ol {
        margin-left: -#{$list-space};
      }
      li {
        margin-top: 14px;
        margin-bottom: 0;
        &::before {
          content: counters(item, '.') ' ';
        }
      }
      strong {
        font-size: 14px;
        margin-right: 12px;
      }
    }
    table {
      margin: 28px 0px 28px -#{$list-space};
      border: $table-border;
      tr {
        & + tr {
          border-top: $table-border;
        }
        &:nth-child(even) {
          background-color: #F5F5F5;
        }
      }
      td {
        width: calc(100% / 9);
        padding: 18px 12px;
        font-size: 12px;
        font-weight: 600;
        & + td {
          border-left: $table-border;
        }
      }
    }
  }
}

@media print {
  body * {
    visibility: hidden;
  }
  .scheduleContainer * {
    visibility: visible;
  }
  .scheduleContainer {
    z-index: 9999;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 0 !important;
    background-color: #fff;
    .header {
      margin-bottom: 35px;
    }
    ol {
      li {
        margin-bottom: 10px;
        li {
          margin-top: 10px;
        }
      }
    }
  }
}