.toolbar {
  h6[class^='MuiTypography-root-'] {
    font-size: 1rem !important;
    font-weight: 600;
    margin-left: '5px';
  }

  div[class^='MuiToolbar-root-'] {
    align-items: initial;
    flex-direction: column;
    background-color: white;
    padding: 10px 0 !important;
  }

  div[class^='MuiFormControl-root-'] {
    padding-left: 0px;
    padding-bottom: 10px;
  }
}
