$white: #fff;

// override theme color
// see _variables.scss from bootstrap
$primary: #6f42c1;
$body-bg: $white;
$activeColor: #fd7e14;
$mainBgColor: $white;
$sideBgColor: #343a40;

$footerHeight: 30px;

$cl-primary: #1AA41A;
$cl-secondary: #F5F7F5;
$cl-text: #333;
$cl-green: #00B019;
$cl-white: #FFF;
$cl-greyC: #CCC;
$cl-greyE6: #E6E6E6;
$cl-grey7F: #7F7F7F;
