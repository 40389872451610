.notificationContainer {
  z-index: 99;
  max-width: 25%;
  top: 85px;
  right: 20px;
  & > div {
    display: flex;
    align-items: flex-end;
    padding-right: 25px;
  }
  button {
    position: absolute;
    top: 0px;
    right: 2px
  }
  .multiline {
    white-space: pre-line;
  }
}

.notificationEnter {
  opacity: 0.01;
}

.notificationEnter.notificationEnterActive {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.notificationExit {
  opacity: 1;
}

.notificationExit.notificationExitActive {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}
